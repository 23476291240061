import React, { Component } from "react";
import { Row, Grid } from "react-bootstrap";
import { Card } from "reactstrap";

class ModuleList extends Component {
  render() {
    return (
      <div className={"card"} style={{ padding: "10px" }}>
        <div className={"header"}>
          <div className="pull-left">
            <h5 className="title" style={{ fontWeight: "600" }}>Module List</h5>
          </div>
        </div>
        <div className="clearfix" />
        <div className={"content"}>
          <List
            data={this.props.selectedModuleList}
            changeModuleID={this.props.changeModuleID}
            moduleId={this.props.moduleId}
          />
          <div className="footer" />
        </div>
      </div>
    );
  }
}
export default ModuleList;

class List extends Component {
  render() {
    var rows = [];

    this.props.data.map((ModuleData, index) =>
      rows.push(
        <ModuleListCard 
          key={index}
          ModuleData={ModuleData}
          changeModuleID={this.props.changeModuleID}
          moduleId={this.props.moduleId}
        />
      )
    );

    return (
      <div
        style={{
          height: "200px",
          overflowY: "scroll",
          overflowX: "hidden",
          margin: "0px"
        }}
      >
        <Card>
          {rows}
        </Card>
      </div>
    );
  }
}

class ModuleListCard extends Component {
  render() {
    // handle Background Color
    var backStyle;
    if (this.props.moduleId === this.props.ModuleData.moduleId) {
      backStyle = { background: "#dcdcdc" };
    } else {
      backStyle = {
        border: "1px solid rgba(0,0,0,.125)",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 2px",
        cursor: "pointer"
      };
    }

    return (
      <div
        onClick={() =>
          this.props.changeModuleID(1, this.props.ModuleData.moduleId)
        }
      >
        <div className="card" style={backStyle} >
          <div className={"header"}>
            <div className="pull-left">
              <h6 className="title" style={{ fontWeight: "600" }}>{this.props.ModuleData.moduleName}</h6>
              <p className="category" style={{ fontSize: "1.2rem" }}>ID: {this.props.ModuleData.moduleId}</p>
            </div>
          </div>
          <div className="clearfix" />
          <div className="content">{this.props.content}</div>
        </div>
      </div>
    );
  }
}
