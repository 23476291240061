import React from "react";
import { AuthProvider } from "@asgardeo/auth-react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.2.0";
import "./assets/css/demo.css";
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "./assets/css/pe-icon-7-stroke.css";
import indexRoutes from "./routes/index.jsx";
import HomePage from "./HomePage.js";
import { authConfigs } from "./Configs.js";
import NotFound from "./components/NotFound.js";

const App = () => {
  return (
    <AuthProvider config={authConfigs}>
      <BrowserRouter>
        <Switch>
          <Route to="/" component={HomePage} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
