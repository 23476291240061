import React from 'react';
import { Grid } from "@material-ui/core";

const NoAccessPage = () => {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{ paddingTop: "20%" }}
        >
            <div>
                <h1 style={{fontWeight: 600}}>Access to this page is restricted</h1>
                <div style={{textAlign:"center"}}>You are not authorized to access this application.</div>
            </div>
        </Grid>
    );
}
 
export default NoAccessPage;