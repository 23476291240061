import DataKeeper from "../views/DataKeeper/DataKeeper";
import Tools from "../views/Tools/Tools";

const dashboardRoutes = [
  {
    path: "/data-manager",
    name: "Module Manager",
    icon: "pe-7s-albums",
    component: DataKeeper,
  },
  {
    path: "/tools",
    name: "Tools",
    icon: "pe-7s-tools",
    component: Tools,
  },
  { redirect: true, path: "/", to: "/data-manager", name: "Module Manager" },
];

export default dashboardRoutes;
