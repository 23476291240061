import React, { Component } from "react";
import { Col } from "react-bootstrap";
import {
  ButtonGroup,
  Switch,
  Popover,
  Menu,
  Button,
  Position,
  MenuItem,
  Classes,
  H5,
  Tag
} from "@blueprintjs/core";
import ModuleEdit from "./Form/Module/ModuleEdit";
import PaperManagerView from "./PaperManager/PaperManagerView";

export class ModuleCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paperWizadShow: false,
      checked: false,
      showEditMode: false,
      moduleName: this.props.repo.moduleName,
      moduleId: this.props.repo.moduleId,
      productName: this.props.Product,
      productId: this.props.productID
    };

    this.TogglePaperWizadMode = () => {
      this.setState({ paperWizadShow: !this.state.paperWizadShow });

      this.props.PaperWizadIDSet(
        !this.state.paperWizadShow === true ? this.state.moduleId : null
      );
      this.props.PaperWizadModuleName(this.state.moduleName);
    };
  }

  toggleEditMode = () => {
    this.setState({ showEditMode: !this.state.showEditMode });
  };

  render() {
    var ModuleCardView;

    if (!this.state.paperWizadShow) {
      if (this.props.PaperWizadID === null) {
        ModuleCardView = (
          <Col>
            <div style={{ border: "0.05px solid #D3D3D3", marginBottom: "4px" }}>
              <div className={"header"} style={{ alignItems: "center" }}>
                <div className="pull-left">
                  <span style={{ fontWeight: "700" }}>{this.props.title} </span>
                  <div style={{ marginTop: "0.5rem", marginBottom: "1rem", fontSize: "12px" }}>ID: {this.props.id}</div>
                </div>
                <div className="pull-right">
                  <ButtonGroup style={{ alignItems: "center" }} minimal={true}>
                    <Tag intent="success" style={{ height: "10px", marginRight: "0.5rem"}}>{this.props.Product}</Tag>
                    <Tag intent="success" style={{ height: "10px", marginRight: "2rem"}}>{this.props.questionType}</Tag>
                    <Button style={{ height: "10px", margin: "auto 0" }} onClick={() => this.TogglePaperWizadMode()}>Paper Manager</Button>
                    <Popover content={<ModuleMenu OpenEdit={this.toggleEditMode} />} position={Position.RIGHT_TOP}>
                      <Button icon="cog" style={{ padding: "0px" }} />
                    </Popover>
                  </ButtonGroup>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          </Col>
        );
      }
    } else {
      ModuleCardView = (
        <PaperManagerView
          TogglePaperWizadMode={this.TogglePaperWizadMode}
          moduleId={this.state.moduleId}
          moduleName={this.state.moduleName}
          productName={this.props.repo.productName}
          productId={this.props.repo.productId}
          questionType={this.props.repo.questionType}
          Notification={this.props.Notification}
        />
      );
    }

    return (
      <div>
        <ModuleEdit
          show={this.state.showEditMode}
          handleHide={this.toggleEditMode}
          dataFetch={this.props.dataFetch}
          handleClick={this.props.handleClick}
          // Module Info Pass for Edit Form
          moduleID={this.state.moduleId}
          moduleName={this.state.moduleName}
          questionType={this.props.questionType}
          productName={this.props.product}
          productId={this.props.productID}
          isEmpty={this.props.repo.isEmpty}
          // for Motifiacation Handle
          Notification={this.props.Notification}
        />
        {ModuleCardView}
      </div>
    );
  }
}

export default ModuleCard;

function ModuleMenu(props) {
  return (
    <Menu className={Classes.ELEVATION_1}>
      <MenuItem icon="edit" onClick={props.OpenEdit} text="Edit" />
      <MenuItem icon="trash" intent="danger" text="Delete" />
    </Menu>
  );
}
