import React, { Component } from "react";
import { StatsCard } from "../../../components/StatsCard/StatsCard.jsx";
import { AppConfig } from "../../../config/config.js";
import withUseApiService from "../../../utils/withUseApiService.jsx";
class ActivePaperType extends Component {
  state = { usage: 0, loading: true, time: null, error: false };
  dataFetch = async () => {
    this.apiService = this.props.apiService;
    this.setState({
      loading: true,
      time: Date(Date.now()),
    });

    this.apiService.getApiInstance()
      .get(
        AppConfig.serviceUrls.paperCount
      )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            loading: false,
            error: false,
            time: Date(Date.now()),
            usage: res.data.count,
          });
        } else {
          this.setState({
            loading: false,
            error: true,
            time: Date(Date.now()),
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: true,
          time: Date(Date.now()),
        });
      });
  };

  componentDidMount() {
    this.dataFetch();
  }

  render() {
    return (
      <StatsCard
        loading={this.state.loading}
        error={this.state.error}
        bigIcon={<i className="pe-7s-copy-file text-primary" />}
        statsText="Published Papers"
        statsValue={this.state.usage + " Papers"}
        statsIcon={<i className="fa fa-refresh" />}
        statsIconText="date"
      />
    );
  }
}

export default withUseApiService(ActivePaperType);
