import React, { Component } from "react";
import { Breadcrumb, BreadcrumbItem, Row, Col } from "reactstrap";

import {
  Drawer,
  Classes,
  FormGroup,
  InputGroup,
  Button,
  Tooltip,
  Position,
  Switch,
  FileInput,
  TagInput,
  Alert,
} from "@blueprintjs/core";
import cloneDeep from "lodash/cloneDeep";
import "./css/zoom.css";

import SortableComponent from "./SortableAnswerList";
import QuestionEditor from "./QuestionEditor";
import { DateInput } from "@blueprintjs/datetime";
import config from "../../../../../variables/Constants";
import { AppConfig } from "../../../../../config/config.js";
import withUseApiService from "../../../../../utils/withUseApiService.jsx";

class MCQEditForm extends Component {
  state = {
    EditMode: false,
    ShowSubmitError: false,
    SubmitModeError: "",
    // keep Answer List for Answer Edit Mode
    EditModeAnswer: cloneDeep(this.props.data.answers),
    // keep Remove Answer List
    RemoveList: [],
    data: cloneDeep(this.props.data),
    VersionList: cloneDeep(this.props.data.versionList.split(",")),
    error: {
      Content: false,
      OtherContent: false,
      CreatedBy: false,
      CreatedDate: false,
      VerifiedBy: false,
      VerifiedDate: false,
      IsActive: false,
      IsMultipleSelection: false,
      IsSample: false,
      NumberOfAnswer: false,
      VersionList: false,
    },
    errorMessage: {
      Content: "",
      OtherContent: "",
      CreatedBy: "",
      CreatedDate: "",
      VerifiedBy: "",
      VerifiedDate: "",
      IsActive: "",
      IsMultipleSelection: "",
      IsSample: "",
      NumberOfAnswer: "",
      VersionList: "",
    },
  };
  reset = () => {
    this.setState({
      EditMode: false,
      ShowSubmitError: false,
      SubmitModeError: "",
      // keep Answer List for Answer Edit Mode
      EditModeAnswer: cloneDeep(this.props.data.answers),
      // keep Remove Answer List
      RemoveList: [],
      data: cloneDeep(this.props.data),
      VersionList: cloneDeep(this.props.data.versionList.split(",")),
      error: {
        Content: false,
        OtherContent: false,
        CreatedBy: false,
        CreatedDate: false,
        VerifiedBy: false,
        VerifiedDate: false,
        IsActive: false,
        IsMultipleSelection: false,
        IsSample: false,
        NumberOfAnswer: false,
        VersionList: false,
      },
      errorMessage: {
        Content: "",
        OtherContent: "",
        CreatedBy: "",
        CreatedDate: "",
        VerifiedBy: "",
        VerifiedDate: "",
        IsActive: "",
        IsMultipleSelection: "",
        IsSample: "",
        NumberOfAnswer: "",
        VersionList: "",
      },
    });
  };

  changeContentandDate = (value, key, type) => {
    let retValidator = this.validator(type, value);
    this.setState((prevState) => ({
      data: {
        ...prevState.data, // keep all other key-value pairs
        [key]: value, // update the value of specific key
      },
      error: {
        ...prevState.error, // keep all other key-value pairs
        [key]: retValidator.isValid, // update the value of specific key
      },
      errorMessage: {
        ...prevState.errorMessage, // keep all other key-value pairs
        [key]: retValidator.Message, // update the value of specific key
      },
    }));
  };

  changeSwitch = (key) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data, // keep all other key-value pairs
        [key]: !this.state.data[key], // update the value of specific key
      },
    }));
  };

  changeValue = (event, key, type) => {
    let value = event.target.value;
    let range = {};
    // set range
    let max = parseInt(event.target.max);
    let min = parseInt(event.target.min);
    console.log(min, max);
    if (type === "number_range") {
      if (!isNaN(max) && !isNaN(min)) {
        range["max"] = max;
        range["min"] = min;
      } else {
        console.error("min and max should be set in target object.");
        return;
      }
    }

    if (value !== undefined && type !== undefined) {
      let retValidator = this.validator(type, value, range);
      this.setState((prevState) => ({
        data: {
          ...prevState.data, // keep all other key-value pairs
          [key]: value, // update the value of specific key
        },
        error: {
          ...prevState.error, // keep all other key-value pairs
          [key]: retValidator.isValid, // update the value of specific key
        },
        errorMessage: {
          ...prevState.error, // keep all other key-value pairs
          [key]: retValidator.Message, // update the value of specific key
        },
      }));
    } else {
      console.error("value and type should be set in target object.");
    }
  };

  tagInput = (values, key) => {
    let lastInputValue = values[values.length - 1];
    let lastInputVersion = parseFloat(lastInputValue); // should be 2.6
    let isDecimal = lastInputValue % 1 >= 0;
    let remain = lastInputVersion * 10 - parseInt(lastInputVersion * 10); // use this invalidate 2.31 only one decimal point

    if (!isNaN(lastInputVersion) && remain == 0 && isDecimal) {
      this.setState((prevState) => ({
        [key]: values, // update the value of specific key
        error: {
          ...prevState.error, // keep all other key-value pairs
          [key]: false, // update the value of specific key
        },
        errorMessage: {
          ...prevState.error, // keep all other key-value pairs
          [key]: "", // update the value of specific key
        },
      }));
    } else {
      this.setState((prevState) => ({
        [key]: values, // update the value of specific key
        error: {
          ...prevState.error, // keep all other key-value pairs
          [key]: true, // update the value of specific key
        },
        errorMessage: {
          ...prevState.error, // keep all other key-value pairs
          [key]:
            values.length > 0
              ? "Invalid version, try again "
              : "Invalid Version try again / Version list cannot be empty", // update the value of specific key
        },
      }));
    }
  };

  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState((prevState) => ({
        data: {
          ...prevState.data, // keep all other key-value pairs
          otherContent: "data:image/jpeg;base64," + getBase64(reader.result),
        },
      }));
    };

    reader.readAsDataURL(file);
  }

  toggleShowSubmitError = () => {
    this.setState({ ShowSubmitError: !this.state.ShowSubmitError });
  };

  dropBoxValueChange = (e, key = "DropBoxValue") => {
    let value = e.target.value;
    this.setState((prevState) => ({
      data: {
        ...prevState.data, // keep all other key-value pairs
        [key]: value, // update the value of specific key
      },
    }));
  };

  // Validator
  validator = (type, value, range) => {
    switch (type) {
      case "text":
        // Validate Module Name
        return {
          isValid: value.length === 0,
          Message: value.length === 0 ? "Cannot be empty." : "",
        };
        break;
      case "Content":
        // Validate Module Name
        return {
          isValid: value === "<p><br></p>",
          Message: value === "<p><br></p>" ? "Cannot be empty." : "",
        };
        break;
      case "date":
        // Validate Module Name
        return {
          isValid: value === null,
          Message: value === null ? "Cannot be empty." : "",
        };
        break;
      case "number":
        // Validate Module Name
        return {
          isValid: isNaN(parseInt(value)),
          Message: isNaN(parseInt(value)) ? "Please input a number" : "",
        };
        break;
      case "number_range":
        // Validate Module Name
        if (isNaN(parseInt(value))) {
          return {
            isValid: isNaN(parseInt(value)),
            Message: isNaN(parseInt(value)) ? "Please input a number" : "",
          };
        } else {
          return {
            isValid: parseInt(value) > range.max || parseInt(value) < range.min,
            Message:
              parseInt(value) > range.max || parseInt(value) < range.min
                ? "Number should be in between [ " +
                  range.min +
                  " ," +
                  range.max +
                  "]"
                : "",
          };
        }

        break;

      default:
        break;
    }
  };

  // form Validator
  formValidator = () => {
    var obj = this.state.error;
    var found = Object.keys(obj).filter(function (key) {
      return obj[key] === true;
    });
    console.log(found);
    return !(found.length > 0);
  };

  DataSetValidator = () => {
    let AnswerCount = 0;
    this.state.EditModeAnswer.forEach((CurrentAnswer) => {
      if (CurrentAnswer.isCorrect) {
        AnswerCount += 1;
      }
    });

    if (AnswerCount === 0) {
      return { valid: false, ErrorMessage: "No Answer Found" };
    } else if (AnswerCount < 2 && this.state.data.IsMultipleSelection) {
      return {
        valid: false,
        ErrorMessage:
          "Add More Correct Answer, Hint: This Question is Multiple selection MCQ",
      };
    } else if (
      this.state.EditModeAnswer.length < this.state.data.DisplayAnswerLength
    ) {
      return { valid: false, ErrorMessage: "Number of Display answer error!" };
    } else {
      return { valid: true, ErrorMessage: "" };
    }
  };

  submit = async () => {
    var isValid = this.formValidator();
    var isValidData = this.DataSetValidator();
    if (isValid && isValidData.valid) {
      var updateData = {
        questionData: {
          productId: this.props.productId,
          moduleId: this.props.data.moduleId,
          topicId: this.props.data.topicId,
          content: this.state.data.Content? this.state.data.Content : this.state.data.content,
          otherContent: this.state.data.otherContent,
          createdBy: this.state.data.createdBy,
          createdDate: this.state.data.createdDate,
          verifiedBy: this.state.data.verifiedBy,
          verifiedDate: this.state.data.verifiedDate,
          isActive: this.state.data.isActive === 1,
          isMultipleSelection: this.state.data.isMultipleSelection === 1,
          isSample: this.state.data.isSample === 1,
          numberOfAnswers: parseInt(this.state.data.numberOfAnswers, 10),
          versions: this.state.VersionList,
        },
        answerList: this.state.EditModeAnswer,
        removeList: this.state.RemoveList,
      };
      this.props.apiService.getApiInstance()
        .put(
          AppConfig.serviceUrls.question + this.props.data.id,
          updateData
        )
        .then((res) => {
          
          if (res.status === 201) {
            //this.toggleMode();
            this.props.FetchQuestionData(0);
            this.props.onClose();
            this.props.Notification(
              "tr",
              "success",
              "Question Data Updated successfully",
              "pe-7s-speaker"
            );
          } else {
            this.props.Notification(
              "tr",
              "error",
              "Error Occured",
              "pe-7s-speaker"
            );
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    } else {
      this.setState({ SubmitModeError: isValidData.ErrorMessage }, () => {
        this.toggleShowSubmitError();
      });
    }
  };

  deleteImage(e) {
    this.state.data.otherContent = null;
  }

  componentWillReceiveProps() {
    if (this.props.isOpen === true) {
      this.reset();
    }
  }

  handleClear = () => this.tagInput(this.state.data.Tags.length > 0 ? [] : []);
  
  render() {
    const clearButton = (
      <Button
        disabled={false}
        icon={"cross"}
        minimal={true}
        onClick={this.handleClear}
      />
    );
    return (
      <div>
        <Alert
          confirmButtonText="Okay"
          isOpen={this.state.ShowSubmitError}
          onClose={this.toggleShowSubmitError}
        >
          <p>{this.state.SubmitModeError}</p>
        </Alert>
        <Drawer
          isOpen={this.props.isOpen}
          onClose={() => {
            this.props.onClose();
            this.reset();
          }}
          icon="edit"
          size="60%"
          title="Edit MCQ Question"
        >
          <div className={Classes.DRAWER_BODY} style={{ overflowY: "scroll" }}>
            <div className={Classes.DIALOG_BODY}>
              <FormGroup
                helperText={this.state.errorMessage.Content}
                label="Question"
                labelFor="text-input"
                intent="danger"
              >
                <QuestionEditor
                  setEditValue={this.changeContentandDate}
                  content={this.state.data.content}
                />
              </FormGroup>

              <FormGroup
                helperText={this.state.errorMessage.VersionList}
                label="Question Versions"
                labelFor="text-input"
                intent="danger"
              >
                <TagInput
                  onChange={(values) => this.tagInput(values, "VersionList")}
                  intent={this.state.error.VersionList ? "danger" : "success"}
                  placeholder="Question Versions"
                  rightElement={clearButton}
                  values={this.state.VersionList}
                  separator=","
                  tagProps={{ intent: "success" }}
                />
              </FormGroup>

              <FormGroup label="Question Image" labelFor="text-input">
                <Row>
                  <Col sm={6}>
                    <FileInput
                      text="Choose file..."
                      onInputChange={(e) => this.handleImageChange(e)}
                    />
                    <br />
                    {this.state.data.otherContent !== null && (
                      <div>
                        <img
                          // className="zoom"
                          style={{
                            border: "1px solid #ddd",
                            border_radius: "4px",
                            width: "100px"
                          }}
                          alt="Question Image"
                          src={this.state.data.otherContent}
                        />
                        <Button
                          style={{
                            marginLeft: "10px"
                          }}
                          onClick={this.deleteImage}
                          type="submit"
                          text="Delete Image"
                          icon="delete"
                          intent="warning"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </FormGroup>

              <Row>
                <Col sm={12} md={3}>
                  <FormGroup
                    helperText={this.state.errorMessage.CreatedBy}
                    label="Created By"
                    labelFor="text-input"
                    intent="danger"
                  >
                    <InputGroup
                      required={true}
                      placeholder="CreatedBy"
                      value={this.state.data.createdBy}
                      intent={this.state.error.CreatedBy ? "danger" : "success"}
                      onChange={(event) =>
                        this.changeValue(event, "CreatedBy", "text")
                      }
                    />
                  </FormGroup>
                </Col>

                <Col sm={12} md={3}>
                  <FormGroup
                    helperText="Created Date , ex : 2019-05-12"
                    label="Created Date"
                    labelFor="text-input"
                  >
                    <DateInput
                      formatDate={(date) => formatDate(date)}
                      onChange={(date) =>
                        this.changeContentandDate(
                          formatDate(date),
                          "CreatedDate",
                          "date"
                        )
                      }
                      parseDate={(str) => new Date(str)}
                      intent={
                        this.state.error.CreatedDate ? "danger" : "success"
                      }
                      placeholder={"YYYY-MM-DD"}
                      value={new Date(this.state.data.createdDate)}
                      timePrecision={"none"}
                    />
                  </FormGroup>
                </Col>

                <Col sm={12} md={3}>
                  <FormGroup
                    helperText={this.state.errorMessage.VerifiedBy}
                    label="Verified By"
                    labelFor="text-input"
                    intent="danger"
                  >
                    <InputGroup
                      required={true}
                      placeholder="Verified By"
                      value={this.state.data.verifiedBy}
                      intent={
                        this.state.error.VerifiedBy ? "danger" : "success"
                      }
                      onChange={(event) =>
                        this.changeValue(event, "VerifiedBy", "text")
                      }
                    />
                  </FormGroup>
                </Col>
                <Col sm={12} md={3}>
                  <FormGroup
                    helperText="Verified Date , ex : 2019-12-13"
                    label="Verified Date"
                    labelFor="text-input"
                  >
                    <DateInput
                      formatDate={(date) => formatDate(date)}
                      onChange={(date) =>
                        this.changeContentandDate(
                          formatDate(date),
                          "VerifiedDate",
                          "date"
                        )
                      }
                      intent={
                        this.state.error.VerifiedDate ? "danger" : "success"
                      }
                      parseDate={(str) => new Date(str)}
                      placeholder={"YYYY-MM-DD"}
                      defaultValue={new Date(this.state.data.verifiedDate)}
                      timePrecision={"none"}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={3}>
                  <FormGroup
                    label="Answer Display"
                    helperText={this.state.errorMessage.NumberOfAnswer}
                    labelFor="input"
                  >
                    <InputGroup
                      intent={
                        this.state.error.NumberOfAnswer ? "danger" : "success"
                      }
                      placeholder="Placeholder text"
                      type="number"
                      min={2}
                      max={10}
                      value={this.state.data.numberOfAnswers}
                      onChange={(event) => {
                        this.changeValue(
                          event,
                          "NumberOfAnswer",
                          "number_range"
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12}>
                  <hr />
                  <div className="footer" style={{ marginLeft: "40px" }}>
                    <div className="stats">
                      <SortableComponent
                        AnswerList={this.state.EditModeAnswer}
                        RemoveList={this.state.RemoveList}
                        answerLength={this.state.data.numberOfAnswers}
                        SetAnswerLength={this.ChangeAnswerLength}
                        QuestionID={this.state.data.id}
                      />
                    </div>
                  </div>
                  <hr />
                  <Row>
                    <Col sm={12}>
                      <Row>
                        <Col sm={3}>
                          <Switch
                            defaultChecked={this.state.data.isMultipleSelection}
                            label="Multiple Selection "
                            onChange={() =>
                              this.changeSwitch("IsMultipleSelection")
                            }
                          />
                        </Col>
                        <Col sm={3}>
                          <Tooltip
                            content="If enabled, this question will be saved as a sample question. This question will never be included in any genarated papers."
                            position={Position.TOP}
                          >
                            <Switch
                              defaultChecked={this.state.data.isSample}
                              label="Sample Question"
                              onChange={() => this.changeSwitch("IsSample")}
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                </Col>
              </Row>
            </div>
          </div>
          <div className={Classes.DRAWER_FOOTER}>
            <Tooltip content="Save Changes" position={Position.RIGHT}>
              <Button
                onClick={this.submit}
                type="submit"
                text="save"
                icon="saved"
                intent="primary"
              />
            </Tooltip>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default withUseApiService(MCQEditForm);

function getBase64(fileResult) {
  let encoded = "Not Specified";
  if (fileResult) {
    encoded = fileResult.substr(fileResult.indexOf(",") + 1);
  }
  return encoded;
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
