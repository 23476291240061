import React, { Component } from "react";
import {
  Drawer,
  Classes,
  FormGroup,
  InputGroup,
  Button,
  Tooltip,
  Position,
  Spinner,
} from "@blueprintjs/core";
import config from "../../../../variables/Constants";
import { AppConfig } from "../../../../config/config.js";
import withUseApiService from "../../../../utils/withUseApiService.jsx";

class ModuleInsert extends Component {
  constructor(prevProps, props) {
    super(prevProps, props);
    this.apiservice = this.props.apiService;
    this.state = {
      // use for content handele  loading
      loading: false,
      error: false,
      errorMessage: "",
      productData: [],
      uploading: false,

      data: {
        moduleName: "",
        number: 0,
        tags: [],
        questionType: "MCQ",
        productId: 0,
      },
      error: { moduleName: true },
      errorMessage: { moduleName: "" },
    };
  }
  reset = () => {
    this.setState({
      // use for content handele  loading
      loading: false,
      error: false,
      errorMessage: "",
      productData: [],
      uploading: false,

      data: {
        moduleName: "",
        number: 0,
        tags: [],
        questionType: "MCQ",
        productId: 0,
      },
      error: { moduleName: true },
      errorMessage: { moduleName: "" },
    });
  };

  changeValue = (event, key, type) => {
    let value = event.target.value;
    let range = {};
    // set range
    let max = parseInt(event.target.max);
    let min = parseInt(event.target.min);

    if (type === "number_range") {
      if (!isNaN(max) && !isNaN(min)) {
        range["max"] = max;
        range["min"] = min;
      } else {
        console.error("min and max should be set in target object.");
        return;
      }
    }

    if (value !== undefined && type !== undefined) {
      let retValidator = this.validator(type, value, range);
      this.setState((prevState) => ({
        data: {
          ...prevState.data, // keep all other key-value pairs
          [key]: value, // update the value of specific key
        },
        error: {
          ...prevState.error, // keep all other key-value pairs
          [key]: retValidator.isValid, // update the value of specific key
        },
        errorMessage: {
          ...prevState.error, // keep all other key-value pairs
          [key]: retValidator.Message, // update the value of specific key
        },
      }));
    } else {
      console.error("value and type should be set in target object.");
    }
  };

  dropBoxValueChange = (e, key) => {
    let value = e.target.value;
    this.setState((prevState) => ({
      data: {
        ...prevState.data, // keep all other key-value pairs
        [key]: value, // update the value of specific key
      },
    }));
  };

  // Validator
  validator = (type, value, range) => {
    switch (type) {
      case "text":
        // Validate Module Name
        return {
          isValid: value.length === 0,
          Message: value.length === 0 ? "Module Name connot be empty." : "",
        };
        break;
      case "number":
        // Validate Module Name
        return {
          isValid: isNaN(parseInt(value)),
          Message: isNaN(parseInt(value)) ? "Please input a number" : "",
        };
        break;
      case "number_range":
        // Validate Module Name
        if (isNaN(parseInt(value))) {
          return {
            isValid: isNaN(parseInt(value)),
            Message: isNaN(parseInt(value)) ? "Please input a number" : "",
          };
        } else {
          return {
            isValid: parseInt(value) > range.max || parseInt(value) < range.min,
            Message:
              parseInt(value) > range.max || parseInt(value) < range.min
                ? "Number should be in between [ " +
                  range.min +
                  " ," +
                  range.max +
                  "]"
                : "",
          };
        }

        break;

      default:
        break;
    }
  };

  // form Validator
  formValidator = () => {
    var obj = this.state.error;
    var found = Object.keys(obj).filter(function (key) {
      return obj[key] === true;
    });
    console.log(found);
    return !(found.length > 0);
  };

  // axios operation
  fetchProducts = async () => {
    this.setState({
      loading: true,
    });

    this.apiservice.getApiInstance()
      .get(
        AppConfig.serviceUrls.products
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.products.length > 0) {
            this.setState((prevState) => ({
              productData: res.data.products,
              loading: false,
              data: {
                ...prevState.data, // keep all other key-value pairs
                productId: res.data.products[0].productId, // update the value of specific key
              },
            }));
          } else {
            this.setState({
              error: true,
              errorMessage: "No Product Data Found ",
              loading: false,
            });
          }
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  submit = async () => {
    var isValid = this.formValidator();
    if (isValid) {
      this.setState({ uploading: true });
      // TODO: TEMP CHANGE isActive to true/false (Hard coded values)
      var postData = {
        productId: parseInt(this.state.data.productId, 10),
        moduleName: String(this.state.data.moduleName),
        questionType: String(this.state.data.questionType),
        isActive: 0,
      };

      this.apiservice.getApiInstance()
        .post(
          AppConfig.serviceUrls.modules,
          postData
        )
        .then((res) => {
          if (res.status === 201) {
            this.props.handleHide();
            this.props.dataFetch();
            this.props.handleClick(
              "tr",
              "success",
              "Data insert successfully",
              "pe-7s-speaker"
            );
          } else {
            this.props.handleHide();
            this.props.dataFetch();
            this.props.handleClick(
              "tr",
              "error",
              "Inserted Data has been rejected by server Process ",
              "pe-7s-speaker"
            );
          }
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
          this.props.handleHide();
          this.props.dataFetch();
          this.props.handleClick(
            "tr",
            "error",
            "Service have been gone offline. ",
            "pe-7s-speaker"
          );
        })
        .finally(() => {
          this.setState({ uploading: false });
        });
    }
  };

  componentWillReceiveProps(prevProps, props) {
    if (prevProps.show !== true) {
      this.reset();
      this.fetchProducts();
    }
  }

  render() {
    return (
      <Drawer
        isOpen={this.props.show}
        onClose={this.props.handleHide}
        icon="info-sign"
        size="450px"
        title="Add new module"
      >
        {this.state.Error ? (
          <ErrorMessage ErrorMessage={this.state.errorMessage} />
        ) : this.state.loading ? (
          <LoadingMessage />
        ) : (
          <div>
            <div className={Classes.DRAWER_BODY}>
              <div className={Classes.DIALOG_BODY}>
                <FormGroup
                  helperText={this.state.errorMessage.moduleName}
                  label="Module Name"
                  labelFor="text-input"
                  intent="danger"
                >
                  <InputGroup
                    intent={this.state.error.moduleName ? "danger" : "success"}
                    placeholder="ex : API Manager Level 01"
                    type="text"
                    value={this.state.data.moduleName}
                    onChange={(event) => {
                      this.changeValue(event, "moduleName", "text");
                    }}
                  />
                </FormGroup>

                <FormGroup
                  helperText={this.state.errorMessage.tags}
                  label="Product Name"
                  labelFor="text-input"
                  intent="danger"
                >
                  <div className="bp3-select bp3-fill">
                    <select
                      value={this.state.data.productId}
                      onChange={(event) => {
                        this.dropBoxValueChange(event, "productId");
                      }}
                    >
                      {this.state.productData.map((Instance, index) => {
                        return (
                          <option
                            key={index}
                            value={Instance.productId}
                            onClick={(event) => {
                              console.log(event);
                            }}
                          >
                            {Instance.productName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </FormGroup>

                <FormGroup
                  helperText={this.state.errorMessage.Tags}
                  label="Question Type"
                  labelFor="text-input"
                  intent="danger"
                >
                  <div className="bp3-select bp3-fill">
                    <select
                      value={this.state.questionType}
                      onChange={(event) => {
                        this.dropBoxValueChange(event, "questionType");
                      }}
                    >
                      <option value="MCQ">MCQ Questions</option>
                      <option value="PRACTICAL">Practical Questions</option>
                      <option value="SCENARIO_BASE">
                        Scenario Base Questions
                      </option>
                    </select>
                  </div>
                </FormGroup>
              </div>
            </div>
            <div
              className={Classes.DRAWER_FOOTER}
              style={{ position: "absolute", bottom: "0px", width: "100%" }}
            >
              <Tooltip content="Save" position={Position.RIGHT}>
                <Button
                  icon="saved"
                  type="submit"
                  text="save"
                  intent="primary"
                  onClick={this.submit}
                />
              </Tooltip>
            </div>
          </div>
        )}
      </Drawer>
    );
  }
}

export default withUseApiService(ModuleInsert);

function LoadingMessage(props) {
  return (
    <center>
      <h4>
        <Spinner size={30} />
        <p className="category">Loading...</p>
      </h4>
    </center>
  );
}

function ErrorMessage(props) {
  return (
    <center>
      <h4>
        <i className="fa fa-exclamation-triangle" />
        <p className="category">{props.ErrorMessage}</p>
      </h4>
    </center>
  );
}
