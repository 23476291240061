export const BaseUrl = window?.config?.APP_SERVICE_URL;

export const AppConfig = {
  serviceUrls: {
    paperCount: `${BaseUrl}/papers/count`,
    dbUsage: `${BaseUrl}/db-usage/count`,
    paperSnapErrorCount: `${BaseUrl}/paper-snap-errors/count`,
    userCount: `${BaseUrl}/users/count`,
    module: `${BaseUrl}/exam-module`,
    modules: `${BaseUrl}/exam-modules/`,
    products: `${BaseUrl}/products/`,
    paperType: `${BaseUrl}/paper-types/`,
    categories: `${BaseUrl}/categories/`,
    papers: `${BaseUrl}/papers/`,
    questionPool: `${BaseUrl}/question-pool`,
    question: `${BaseUrl}/questions/`
  }
};
