import React, { Component } from "react";
import { Row, Col } from "reactstrap";

// List Component
import ModuleList from "./ModuleList";
import PaperList from "./PaperList";

import PaperViewModal from "./PaperViewModal";
import { Button } from "@blueprintjs/core";

import config from "../../../variables/Constants";
import { AppConfig } from "../../../config/config.js";
import withUseApiService from "../../../utils/withUseApiService.jsx";

class PaperGenarator extends Component {
  constructor(props, prevState, state) {
    super(props, prevState, state);
    this.apiService = this.props.apiService;
    this.state = {
      PaperShow: false,
      loadingModule: false,
      loadingPaper: false,
      // IDs
      selectedModuleID: null,
      selectedPaperID: null,
      //List Data
      selectedModuleList: [],
      selectedPaperList: [],
      GenaratedPaper: [],

      GenaratingPaper: false,

      GenaratingMessage: "",
    };
  }

  handleHide = () => {
    this.setState({
      PaperShow: !this.state.PaperShow,
      GenaratingMessage: "",
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedModuleID !== this.state.selectedModuleID) {
      this.setState({ selectedPaperID: null, selectedPaperList: [] }, () =>
        this.fetchPaperList()
      );
    }
  }

  setSelectionID = (selectionID, id) => {
    // chnage Module ID
    if (selectionID === 1) {
      this.setState({ selectedModuleID: id });
    }
    // Chnage Paper ID
    if (selectionID === 2) {
      this.setState({ selectedPaperID: id });
    }
  };

  FetchGenaratedPaperData = async () => {
    let url = AppConfig.serviceUrls.modules + this.state.selectedModuleID + '/papers/' + this.state.selectedPaperID;
    this.setState({ GenaratingPaper: true });
    this.apiService.getApiInstance()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.generatedQuestionSet.length > 0) {
            this.setState({
              GenaratedPaper: res.data,
              GenaratingPaper: false,
              GenaratingMessage: "Generate a Paper",
            });
            this.handleHide();
          } else {
            this.setState({
              GenaratingPaper: false,
              GenaratingMessage: "Not enough Question Pool",
            });
          }
        } else {
          this.setState({
            GenaratingPaper: false,
            GenaratingMessage:
              "Not enough Question Pool/ or Some error has been occured",
          });
        }
      })
      .catch((err) => {
        this.setState({
          GenaratingPaper: false,
          GenaratingMessage: "Not enough Question Pool",
        });
      });
  };

  fetchPaperList = async () => {
    let url = AppConfig.serviceUrls.modules + this.state.selectedModuleID + '/papers';
    this.setState({
      loadingPaper: true,
    });
    this.apiService.getApiInstance()
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            selectedPaperList: res.data.papers,
            loadingPaper: false,
          });
        }
        if (res.status === 400) {
          this.setState({
            selectedPaperList: [],
            loadingPaper: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          selectedPaperList: [],
          loadingPaper: false,
        });
      });
  };

  FertchModuleList = async () => {
    this.setState({
      loadingModule: true,
      GenaratingMessage: "",
    });
    this.apiService.getApiInstance()
      .get(
        AppConfig.serviceUrls.modules,
        
      )
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            selectedModuleList: res.data.examModules,
            loadingModule: false,
          });
          console.log(res.data);
        }
        if (res.status === 400) {
        }
      })
      .catch((err) => {
        this.setState({
          ToggleStateMessage:
            "Service down check the Server or Check your Connection  ",
        });
      });
  };

  componentDidMount() {
    this.FertchModuleList();
  }

  render() {
    return (
      <div className={"card"}>
        <div className={"header"}>
          <div className="pull-left">
            <h5 className="title" style={{marginBottom: "5px"}}>Paper Generator</h5>
            <p className="category">Test your module paper and verify</p>
          </div>
          <div className="pull-right">
            <Button minimal={true} onClick={() => this.FertchModuleList()}>
              <i className="fa fa-refresh " title="Refresh" />
            </Button>
          </div>
        </div>
        <div className="clearfix" />
        <div style={{ padding: "45px" }} className={"content"}>
          {this.state.loadingModule && (
            <p className="category"> Data Fetching...</p>
          )}
          {!this.state.loadingModule && (
            <div>
              <Row>
                <Col sm={6}>
                  <ModuleList
                    selectedModuleList={this.state.selectedModuleList}
                    changeModuleID={this.setSelectionID}
                    moduleId={this.state.selectedModuleID}
                  />
                </Col>

                <Col sm={6}>
                  <PaperList
                    selectedPaperList={this.state.selectedPaperList}
                    changePaperID={this.setSelectionID}
                    PaperID={this.state.selectedPaperID}
                    loadingPaper={this.state.loadingPaper}
                  />

                  <br />
                  <p className="category">{this.state.GenaratingMessage}</p>
                  <PaperViewModal
                    show={this.state.PaperShow}
                    handlePaperHide={this.handleHide}
                    moduleId={this.state.selectedModuleID}
                    PaperID={this.state.selectedPaperID}
                    GenaratedPaper={this.state.GenaratedPaper}
                  />
                </Col>
              </Row>
              {/* <div className="pull-right"> */}
                {/* <Col sm={6} />
                <Col sm={6}> */}
                  <Button className="pull-right"
                    disabled={this.state.selectedPaperID === null}
                    loading={this.state.GenaratingPaper}
                    onClick={this.FetchGenaratedPaperData}
                    icon="cog"
                    intent="success"
                  >
                    Generate
                  </Button>
                {/* </Col> */}
              </div>
            // </div>
          )}
          {/* <div className="footer" /> */}
        </div>
      </div>
    );
  }
}

export default withUseApiService(PaperGenarator);
