import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";

import indexRoutes from "./routes/index.jsx";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.2.0";
import "./assets/css/demo.css";
import "../node_modules/normalize.css";
import "../node_modules/@blueprintjs/core/lib/css/blueprint.css";
import "../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css";
import "./assets/css/pe-icon-7-stroke.css";

import App from "./App.js";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
