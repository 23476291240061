import React, { Component } from "react";
export class ModuleMainCard extends Component {
  render() {
    return (
      <div className={"card" + (this.props.plain ? " card-plain" : "")}>
        <div className={"header" + (this.props.hCenter ? " text-center" : "")}>
          <div className="pull-left">
            <div>{this.props.title}</div>
          </div>
          {this.props.showButton && (
            <div className="pull-right">{this.props.buttonSet}</div>
          )}
        </div>

        <div className="clearfix" />
        <div className={"content"} style={{ padding: "5rem" }}>
          {this.props.content}
        </div>
      </div>
    );
  }
}

export default ModuleMainCard;
