import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import DataBaseStat from "./StatsCards/DataBaseStat";
import ErrorPaperSnap from "./StatsCards/ErrorPaperSnap";
import ActivePaperType from "./StatsCards/ActivePaperTypeCount";
import UserCount from "./StatsCards/NumberOfUser";

class Dashboard extends Component {
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col lg={3} sm={6}>
              <DataBaseStat />
            </Col>
            <Col lg={3} sm={6}>
              <ErrorPaperSnap />
            </Col>
            <Col lg={3} sm={6}>
              <ActivePaperType />
            </Col>
            <Col lg={3} sm={6}>
              <UserCount />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
