import React, { Component } from "react";
import PaperGenerator from "./PaperGenarator/PaperGenarator";
import PaperAnalyzer from "./PaperSnapAnalyzer/PaperSnapAnalyzer";
import { Row, Col } from "react-bootstrap";

class Tools extends Component {
  render() {
    return (
      <div className={"card"}>
        <div className={"header"}>
          <div className="pull-left">
          </div>
        </div>
        <div className="clearfix" />
        <div className={"content"}>
          <PaperGenerator />
          <PaperAnalyzer />
        </div>
      </div>
    );
  }
}

export default Tools;
